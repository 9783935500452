import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { includes } from 'lodash';
import { isNewLayout } from '@constants/config';
import FloatingBar from '@components/map/floating-bar';
import OldFloatingBar from '@components/map/floating-bar-old';
import LayersMenu from '@components/map/layers-menu';
import MapLegend from '@components/overlap/map-legend';
import MapComponents from '@components/overlap/map-components';
import OverlapContext from '@components/overlap/overlap-context';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import FilePreview from '@shared/files/file-preview';
import { segmentsToBounds } from '@utils/map-utils';
import '../data-detail/data-detail.scss';

const Map = () => {
  const {
    onMapLoad,
    segments,
    selectedSegmentIds
  } = useContext(OverlapContext);
  const [map, setMap] = useState(null);

  const onLoad = useCallback(mapInstance => {
    if (!window.mapSetup) {
      window.initMap();
    }
    window.mapInstance = mapInstance;
    setMap(mapInstance);
    onMapLoad();
  }, [onMapLoad, setMap]);

  const filteredSegments = useMemo(
    () => {
      if (segments && selectedSegmentIds) {
        return segments.filter(
          segment => segment?.shape && includes(selectedSegmentIds, segment?.id)
        );
      }
      return segments;
    }, [segments, selectedSegmentIds]
  );

  const fitSegmentBounds = useCallback(
    () => {
      if (filteredSegments?.length > 0) {
        const bounds = segmentsToBounds(filteredSegments);
        if (bounds) {
          map.fitBounds(bounds);
          if (map.getZoom() > 18) {
            map.setZoom(18);
          }
        }
      }
    },
    [filteredSegments, map]
  );

  useEffect(() => {
    if (map) {
      fitSegmentBounds();
    }
  }, [fitSegmentBounds, map, segments?.length, selectedSegmentIds]);

  return (
    <div styleName="details-map-content" key="map">
      <FilePreview />
      {isNewLayout() && <FloatingBar tools={false} />}
      {!isNewLayout() && <OldFloatingBar tools={false} />}
      <LayersMenu style={{ right: '4rem' }} />
      <MapLegend />
      <DotMapsGoogleMap components={<MapComponents />} onMapLoad={onLoad} />
    </div>
  );
};

export default memo(Map);
