/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearLogs, fetchLogsData, logEntityActivityComment } from '@actions/logs-actions';
import LogItem from '@shared/comments/log-item';
import CommentAvatarInput from '@shared/comments/comment-avatar-input';
import './comment.scss';
import { SelectField, MenuItem } from 'material-ui';
import { commentSortSelect } from '@constants/mui-theme';
import NoCommentsIcon from '@icons/no-comments-icon.svg';

const SORT_ASC = 'entry__id';
const SORT_DESC = '-entry__id';

const EntityComments = () => {
  const { data } = useSelector(state => state.dataDetail);
  const { list, next, reload, count } = useSelector(state => state.logs);
  const [sortOrder, setSortOrder] = useState(SORT_DESC);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLogs());
    dispatch(fetchLogsData('map', 'entity', data.id, null, { filters: 'comments_only_filters' }, sortOrder));
  }, [data.id, sortOrder, dispatch]);

  useEffect(() => {
    if (reload) {
      dispatch(clearLogs());
      dispatch(fetchLogsData('map', 'entity', data.id, null, { filters: 'comments_only_filters' }, sortOrder));
    }
  }, [reload, data.id, sortOrder, dispatch]);

  const onScroll = useCallback(
    event => {
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && next) {
        dispatch(fetchLogsData('map', 'entity', data.id, next, { filters: 'comments_only_filters' }));
      }
    }, [data.id, dispatch, next]);

  const onComment = (comment, mentions) => {
    const payload = {
      id: data.id,
      comment,
      mentions
    };
    dispatch(logEntityActivityComment(payload));
  };

  const onSortOrderChange = useCallback(
    (event, index, value) => {
      if (sortOrder === value) {
        return;
      }
      setSortOrder(value);
    },
    [sortOrder]
  );

  const buildBody = () => {
    let body = (
      <div styleName="comment-body-empty-wrapper">
        <CommentAvatarInput onComment={onComment} />
        <div styleName="comment-body-empty">
          <img styleName= "comment-body-img" src={NoCommentsIcon} alt="NoCommentIcon"/>
          <div styleName="comment-body-empty-text">No comments yet</div>
          <div styleName="comment-body-empty-subtext">Leave a comment to get others updated with changes. Or discuss details.</div>
        </div>
      </div>
    );

    if (list && list.length > 0) {
      body = (<div styleName="comment-body">
        <CommentAvatarInput onComment={onComment} />
        {list.map((item, index) => <LogItem key={index} item={item} />)}
      </div>
      );
    }
    return body;
  };

  const headerCountText = () => {
    return count ? count : 0;
  };

  return (
    <div styleName="comment-container" onScroll={onScroll}>
      <div styleName="comment-content">
        <div styleName="comment-header">
          <div styleName="comment-header-text"> { headerCountText() } Comments</div>
          <div styleName="comment-sort-order">
            <SelectField onChange={onSortOrderChange} value={sortOrder} {...commentSortSelect}>
              {[
                <MenuItem key={SORT_DESC} primaryText="Newest first" value={SORT_DESC} />,
                <MenuItem key={SORT_ASC} primaryText="Oldest first" value={SORT_ASC} />
              ]}
            </SelectField>
          </div>
        </div>
        {buildBody()}
      </div>
    </div>
  );
};

export default EntityComments;
