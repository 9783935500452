/* eslint-disable max-depth */
import React from 'react';
import PropTypes from 'prop-types';
import {
  createNewDataDetail,
  errorDataField,
  fetchDataDetail,
  updateDataField,
  updateSegmentEndpoints,
  updateSegmentField,
  updatePolygon,
  saveAndNavigate,
  saveAndStay,
  navigateAfterSave
} from '@actions/data-detail-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { toggleTraffic, toggleBicycle, toggleTransit } from '@actions/map-actions';
import { uploadStoredAttachments } from '@actions/attachment-actions';
import DataDetail from '@components/data-detail';
import EditMap from '@components/entity/info/map/edit-map';
import FloatingBar from '@components/map/floating-bar';
import OldFloatingBar from '@components/map/floating-bar-old';
import LayersMenu from '@components/map/layers-menu';
import { isNewLayout } from '@constants/config';
import {
  getMetadata,
  getVisibleFields,
  scrollIntoFirstError
} from '@utils/form-utils';
import { convertFromGoogleMapsShape } from '@utils/map-tools-utils';
import '../forms/forms.scss';

// Data detail base class for edition.
class EditDetail extends DataDetail {
  state = {
    activeSegmentId: null,
    activeType: null,
    activeStart: null,
    activeEnd: null,
    geometryLocked: false,
    activeStep: 0,
    defaultsSet: false
  };

  componentDidMount() {
    const { dataId } = this.props.params;
    if (dataId) {
      this.loadData(dataId);
    } else {
      this.openNew();
    }
  }

  componentDidUpdate = () => {
    const { error } = this.props;
    const metadata = this.getMetadata();
    const visibleFields = getVisibleFields(metadata);
    scrollIntoFirstError(error, metadata, visibleFields);
  };

  getCreateNew = () => {
    const dataType = this.getDataType();
    return this.props.createNewDataDetail(dataType);
  };

  openNew = () => this.getCreateNew();

  getActiveSegment = () => {
    return this.props.data.segments.find(seg => seg.id === this.state.activeSegmentId);
  };

  // eslint-disable-next-line react/display-name
  getMap = () => {
    const segments = this.getSegments();
    return (
      <div style={{ height: '100%', width: '100%' }} key="map">
        {isNewLayout() && <FloatingBar legend={false} tools={false} />}
        {!isNewLayout() && <OldFloatingBar legend={false} tools={false} />}
        <LayersMenu style={{ right: '1rem' }} />
        <EditMap
          activeEnd={this.state.activeEnd}
          activeSegmentId={this.state.activeSegmentId}
          activeStart={this.state.activeStart}
          activeType={this.state.activeType}
          geometryLocked={this.state.geometryLocked}
          segments={segments}
          onActiveSegmentChanged={this.onActiveSegmentChangedByMap}
          onPolygonChanged={this.onActivePolygonChangedByMap}
        />
      </div>
    );
  };

  onActiveSegmentChangedByMap = endPoints => {
    const newEndPoints = {...{start: this.state.activeStart, end: this.state.activeEnd}, ...endPoints};
    this.setState({activeStart: newEndPoints.start, activeEnd: newEndPoints.end});
    this.props.updateSegmentEndpoints(
      newEndPoints, this.state.activeSegmentId,
      this.getActiveSegment().override_street_centering,
      'map'
    );
  };

  onActivePolygonChangedByMap = mapsShape => {
    this.setState({activeStart: null, activeEnd: null});
    const shape = convertFromGoogleMapsShape(mapsShape);
    this.props.updatePolygon(this.state.activeSegmentId, shape, 'map');
  };

  onSegmentFieldError = (segmentId, fieldName, error) => {
    this.props.updateSegmentField(segmentId, fieldName, null, error);
  };

  getFormCallbacks = () => ({
    setActiveStart: activeStart => this.setState({ activeStart }),
    setActiveEnd: activeEnd => this.setState({ activeEnd }),
    setActiveType: activeType => this.setState({ activeType }),
    setActiveSegmentId: activeSegmentId => this.setState({ activeSegmentId }),
    setGeometryLocked: geometryLocked => this.setState({ geometryLocked })
  });

  getMetadata = () => {
    const { data, options } = this.props;
    return getMetadata(data, this.getDataType(), options);
  };
}

EditDetail.propTypes = {
  createNewDataDetail: PropTypes.func,
  errorDataField: PropTypes.func,
  location: PropTypes.object,
  navigateAfterSave: PropTypes.func,
  openDashboardDialog: PropTypes.func,
  saveAndNavigate: PropTypes.func,
  saveAndStay: PropTypes.func,
  updateDataField: PropTypes.func,
  updateSegmentEndpoints: PropTypes.func,
  updateSegmentField: PropTypes.func
};

export const editMapDispatchToProps = {
  createNewDataDetail,
  errorDataField,
  fetchDataDetail,
  navigateAfterSave,
  openDashboardDialog,
  saveAndNavigate,
  saveAndStay,
  toggleBicycle,
  toggleTraffic,
  toggleTransit,
  updateDataField,
  updateSegmentEndpoints,
  updateSegmentField,
  updatePolygon,
  uploadStoredAttachments
};

export default EditDetail;
