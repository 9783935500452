import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { includes } from 'lodash';

import { getPermissions } from './selectors';
import { getDataTypeConfig } from '../selectors';

import {
  AGENCY_DATA_TYPE,
  ENTITY_DATA_TYPE,
  GROUP_DATA_TYPE,
  OVERLAP_DATA_TYPE,
  USER_DATA_TYPE
} from '../constants';

import { allowedForAgency, isAdminAgency, isAppAdmin } from '@utils/permission-utils';
import { PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER } from '@constants/permission';

const checkEntityPermissions = (agencyId, subType, permission, typeConfig, permissions) => {
  if (permission === 'add') {
    if (typeConfig?.attributes?.disableUiAdd) {
      return false;
    }
  }
  // For add/change/delete permissions, disable access if 'roedit' is present:
  if (permissions && permission !== 'view' && includes(permissions, 'roedit')) {
    return false;
  }
  if (isAppAdmin()) {
    return true;
  }
  if (subType === 'agency' && permission === 'add') {
    // Only app admins can add agencies, which is handled with
    // the code above.
    return false;
  }
  if (permissions) {
    // If the 'agency' permission exists, it means access must be restricted within
    // the user's agency. Only entities that are assigned to agencies will have
    // the 'agency' permission set (i.e. projects).
    const agencyCheck = agencyId && includes(permissions, 'agency') ?
      allowedForAgency([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER], agencyId) : true;
    // If the 'pcco' permission exists, check that the user is a PCCO one in order
    // to grant him access:
    const pccoCheck = includes(permissions, 'pcco') ? isAdminAgency() : true;
    // Check for the requested permission (add, change, delete, etc):
    const permissionCheck = includes(permissions, permission);
    return agencyCheck && pccoCheck && permissionCheck;
  }
  return false;
};

const checkGroupPermissions = (permission, permissions) => {
  if (permission !== 'view' && includes(permissions, 'roedit')) {
    return false;
  }
  // App admins can do anything (but check this after 'roedit').
  if (isAppAdmin()) {
    return true;
  }
  if (permissions) {
    return includes(permissions, permission) && isAdminAgency();
  }
  return false;
};

export const useCanAccessType = (agencyId, dataType, subType, permission) => {
  const typeConfig = useSelector(state => getDataTypeConfig(state, ENTITY_DATA_TYPE, subType));
  const permissionsConfig = useSelector(getPermissions);
  const permissions = permissionsConfig[subType];
  return useMemo(() => {
    switch (dataType) {
    case AGENCY_DATA_TYPE:
    case USER_DATA_TYPE:
    case ENTITY_DATA_TYPE:
    case OVERLAP_DATA_TYPE: {
      return checkEntityPermissions(agencyId, subType, permission, typeConfig, permissions);
    }
    case GROUP_DATA_TYPE: {
      return checkGroupPermissions(permission, permissions);
    }
    default:
      return false;
    }
  }, [agencyId, dataType, subType, permission, typeConfig, permissions]);
};

export const useTypeIsReadOnly = (dataType, subType) => {
  const permissionsConfig = useSelector(getPermissions);
  const permissions = permissionsConfig[subType];
  return useMemo(() => {
    switch (dataType) {
    case AGENCY_DATA_TYPE:
    case USER_DATA_TYPE:
    case ENTITY_DATA_TYPE: {
      if (isAppAdmin() && !includes(permissions, 'roedit')) {
        return false;
      }
      return (
        // If it doesn't have add/change/delete permissions, it's read-only:
        !includes(permissions, 'add') &&
        !includes(permissions, 'change') &&
        !includes(permissions, 'delete')
      ) || includes(permissions, 'roedit');
    }
    case OVERLAP_DATA_TYPE:
      return true; // Overlaps are always read only
    case GROUP_DATA_TYPE: {
      return false; // There are no overrides for groups
    }
    default:
      return true;
    }
  }, [dataType, permissions]);
};
