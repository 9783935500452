import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Toggle from 'material-ui/Toggle';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { getToolbarConfig } from '@selectors/map-selector';
import { togglePois, setMapTypeId, toggleTraffic, toggleBicycle, toggleTransit } from '@actions/map-actions';
import { getMapStylers } from '@utils/map-utils';
import PoiMarkerIcon from '@icons/poi-marker-icon';
import { textColor } from '@constants/colors';
import { layersMenuStyles } from '@constants/mui-theme';
import DefaultMapTypeIcon from '@icons/data-uri-icon/default-map-type-icon';
import SatelliteMapTypeIcon from '@icons/data-uri-icon/satellite-map-type-icon';
import TerrainMapTypeIcon from '@icons/data-uri-icon/terrain-map-type-icon';
import { Icon } from '@mui';
import './layers-menu.scss';

class LayersMenu extends Component {
  componentDidMount() {
    if (this.props.poisOn && window.mapInstance) {
      window.mapInstance.setOptions({styles: getMapStylers(this.props.poisOn, this.props.transit)});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.poisOn !== this.props.poisOn && window.mapInstance) {
      window.mapInstance.setOptions({styles: getMapStylers(this.props.poisOn, this.props.transit)});
    }
  }

  setMapType = (event, value) => {
    this.props.setMapTypeId(value);
  };

  render() {
    const { layersMenuOpen, style } = this.props;
    if (!layersMenuOpen) {
      return null;
    }
    const { traffic, transit, bicycle, toolsConfig } = this.props;
    const {
      traffic: trafficConfig = true,
      transit: transitConfig = true,
      bicycle: bicycleConfig = true
    } = toolsConfig;
    const { mapTypeId } = this.props;

    return (
      <Paper styleName={`container ${this.props.small ? 'small' : ''}`} style={style} zDepth={0}>
        <div styleName="header">MAP TYPE</div>
        <div>
          <RadioButtonGroup
            name="layer-controls"
            defaultSelected={mapTypeId}
            valueSelected={mapTypeId}
            onChange={this.setMapType}
            labelPosition="left"
            {...layersMenuStyles.radioGroup}
          >
            <RadioButton
              value="roadmap"
              label={
                <div styleName="label"><DefaultMapTypeIcon style={layersMenuStyles.menuIcons} />Default</div>
              }
              {...layersMenuStyles.radio}
            />
            <RadioButton
              value="hybrid"
              label={
                <div styleName="label"><SatelliteMapTypeIcon style={layersMenuStyles.menuIcons} />Satellite</div>
              }
              {...layersMenuStyles.radio}
            />
            <RadioButton
              value="terrain"
              label={
                <div styleName="label"><TerrainMapTypeIcon style={layersMenuStyles.menuIcons} />Terrain</div>
              }
              {...layersMenuStyles.radio}
            />
          </RadioButtonGroup>
        </div>
        <div styleName="header">MAP DETAILS</div>
        <div>
          <div styleName="input">
            <Toggle
              label={
                <div styleName="label"><PoiMarkerIcon color={textColor} />Places</div>
              }
              name="places"
              toggled={this.props.poisOn}
              onToggle={this.props.togglePois}
            />
          </div>
          {trafficConfig &&
            <div styleName="input">
              <Toggle
                label={
                  <div styleName="label"><Icon>traffic</Icon>Traffic</div>
                }
                name="traffic"
                toggled={traffic}
                onToggle={this.props.toggleTraffic}
              />
            </div>
          }
          {transitConfig &&
            <div styleName="input">
              <Toggle
                label={
                  <div styleName="label"><Icon>directions_transit</Icon>Transit</div>
                }
                name="transit"
                toggled={transit}
                onToggle={this.props.toggleTransit}
              />
            </div>
          }
          {bicycleConfig &&
            <div styleName="input">
              <Toggle
                label={
                  <div styleName="label"><Icon>directions_bike</Icon>Biking</div>
                }
                name="biking"
                toggled={bicycle}
                onToggle={this.props.toggleBicycle}
              />
            </div>
          }
        </div>
      </Paper>
    );
  }
}

LayersMenu.propTypes = {
  bicycle: PropTypes.bool,
  layersMenuOpen: PropTypes.bool,
  mapTypeId: PropTypes.string,
  poisOn: PropTypes.bool,
  setMapTypeId: PropTypes.func,
  small: PropTypes.bool,
  style: PropTypes.object,
  toggleBicycle: PropTypes.func,
  togglePois: PropTypes.func,
  toggleTraffic: PropTypes.func,
  toggleTransit: PropTypes.func,
  toolsConfig: PropTypes.object,
  traffic: PropTypes.bool,
  transit: PropTypes.bool
};


const mapStateToProps = state => {
  const { traffic, transit, bicycle, layersMenuOpen, mapTypeId } = state.map;
  const { poisOn } = state.map.markers;
  const toolsConfig = getToolbarConfig(state);
  return { traffic, transit, bicycle, poisOn, toolsConfig, mapTypeId, layersMenuOpen };
};

export default connect(mapStateToProps, {
  togglePois,
  toggleTraffic,
  toggleTransit,
  toggleBicycle,
  setMapTypeId
})(LayersMenu);
